import { useState } from "react";
import { BiLeftArrowCircle, BiRightArrowCircle } from "react-icons/bi";

function Carasoul({ children }) {
  const [curr, setCurr] = useState(0);
  const prev = () =>
    setCurr((curr) => (curr === 0 ? children.length - 1 : curr - 1));
  const next = () =>
    setCurr((curr) => (curr === children.length - 1 ? 0 : curr + 1));
  return (
    <div className="w-full pt-8 overflow-hidden md:w-2/4 md:pt-0">
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {children}
      </div>
      {/* Controls */}
      <div className="flex items-center justify-between px-4 md:w-full bg-cloudone-gradient-four">
        <div className="flex items-center justify-between ">
          {children.map((_, i) => (
            <span
              className={` ${
                curr === i ? "p-2" : "bg-opacity-50"
              } transition-all block w-[10px] h-[10px] m-1 bg-white rounded-full my-auto`}
            ></span>
          ))}
        </div>
        <div>
          <button onClick={prev} className="text-4xl text-white">
            <BiLeftArrowCircle />
          </button>
          <button onClick={next} className="pl-4 text-4xl text-white">
            <BiRightArrowCircle />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Carasoul;
